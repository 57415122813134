.nav-links {
  font-weight: 600;
    color: rgb(28, 28, 28);
    text-decoration: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    /* background-color: #1888ff; */
    font-size: 22px;
    
    border-radius: 4px;
  }
  
  .nav-links:hover {
    /* background-color: #CB9C0D; */
   
    background-color: black; 
    border-radius: 4px;
    color: white;
    transition: all 0.2s ease-out;
  }
  .navbar {
    background: white;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-size: 1.2rem;
  }
  .nav-menu {
    display: flex;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: center;
    margin-right: 2rem;
    flex-wrap: wrap;
  }
  .nav-item {
    display: block;
    align-items: center;
    /* height: 80px; */
    /* border: 1px solid black; */
    text-align: center;
  }
  .dropItems{
      border: 1px solid black;
  }
  @media screen and (max-width: 960px) {
    .navbar{
      /* width: 100%;
      margin: 10px; */
    }
    .nav-links {
      font-size: 15px;
    }
    .nav-item{
      width: 100%;
    }
    .nav-menu{
      /* border: 1px solid black; */
      text-align: center;
      width: 100%;
    }
}