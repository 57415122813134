



/* Encabezado */
/* header {
    text-align: center;
    padding: 20px;
    background-color: #2c3e50;
    color: #fff;
} */

header h1 {
    font-size: 2.5rem;
}

header p {
    font-size: 1.2rem;
}

/* Sección de productos */
#productos-about {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 30px;
}

.producto {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.producto-imagen {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.producto h3 {
    font-size: 1.5rem;
    color: #053c60;
    margin-top: 10px;
}

.producto p {
    font-size: 1rem;
    margin-top: 10px;
}

/* Sección de empresa */
#empresa {
    margin-top: 40px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#empresa h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    color: #053c60;
}

#empresa p {
    font-size: 1.1rem;
    line-height: 1.8;
}


.capacidades {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: start;
    margin: 20px;
    /* border: 1px solid gray; */
  }
  
  .capacidades h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  
  .capacidades h3 i {
    margin-right: 10px;
    color: #053c60;
  }
  
  /* Estilo para las capacidades */
  .capacidad {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .icono-circulo {
    width: 50px;
    height: 50px;
    background-color: #053c60;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
  }
  
  .icono-circulo i {
    font-size: 20px;
  }
  
  .capacidad p {
    font-size: 16px;
    color: #333;
  }

  .informacion {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
    margin-top: 20px;
    /* border: 2px solid rgb(194, 15, 15); */
  }
  /* Estilo para Misión y Visión */
.mision, .vision {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: start;
    /* border: 2px solid rgb(194, 15, 15); */
  }
  
  .icono-circulo-sombra {
    width: 100px;
    height: 100px;
    border: 2px solid #053c60;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    background-color: white;
    margin-bottom: 15px;
  }
  
  @media (max-width: 768px) {
    .icono-circulo  {
        padding: 0px 15px 0px 15px;
        /* border-radius: 20%; */
    }
}


  .icono-circulo-sombra i {
    font-size: 40px;
    color: #053c60;
  }
  
  .descripcion {
    font-size: 16px;
    color: #333;
    max-width: 400px;
  }
  
  /* Sombra y borde redondeado */
  .icono-circulo-sombra {
    transition: transform 0.3s ease;
  }
  
  .icono-circulo-sombra:hover {
    transform: scale(1.1);
  }


/* Estilo base para el contenedor de los videos */
.video-container {
    
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 56.25%; /* Proporción 16:9 */
    height: 0;
    overflow: hidden;
    margin: 1rem; /* Espacio entre videos */
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

/* Grid para organizar los videos */
.video-grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr); /* 1 columna por defecto */
    gap: 1rem; /* Espacio entre columnas */
}

/* Media query para pantallas grandes: 3 columnas */
@media (min-width: 768px) {
    .video-grid {
        grid-template-columns: repeat(2, 1fr); /* 3 columnas en pantallas grandes */
    }
    
}
