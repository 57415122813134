.container-productos{
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    /* font-size: 50px; */
    z-index: 120;
    /* border: 2px solid rgb(0, 0, 0); */
}

.descripcion-producto{
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
    /* font-weight: lighter; */
    line-height: 1.2em; 
    color: gray;
    font-size: 15px;
    text-align: start;
    padding: 5px 20px 20px 20px;
    background: white;
}

.ContainerMenuProd{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.ContainerLink{
    margin: 10px;
}

@media screen and (max-width: 960px) {
    .card-producto{
        width: 350px;
        text-align: center;
    }
    .card-producto .imagen-producto{
        width: 350px;
    }
}