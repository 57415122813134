.dropdown-menu{
    width: 300px;
    position: absolute;
    
    list-style: none;
    text-align: start;
    z-index: 140;
    margin-top: 1px;
    /* left: 10%; */
    justify-content: center;
}

.dropdown-menu li{
    background:  #000;
    cursor: pointer;
}
.dropdown-menu li:hover{
    background: #CB9C0D;
}

.dropdown-menu.clicked{
    background: cyan;
}

.dropdown-link {
    font-family: 'Quantico', sans-serif;
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #fff;
    padding: 16px;
}

@media screen and (max-width: 960px) {
    .dropdown-menu{
        width: 50%;
        left: calc(100vw - 80%);
    }
}