.title-descubre{
    font-family: 'Fugaz One', cursive;
    font-weight: lighter;
    font-size: 30px;
    margin: 0;
    color: gray;
    padding-top: 40px;
    /* padding-bottom: 12px; */
}

.bodyHome{
    background-image: url(../../../Media/Img/backgrounds/fondoBlanco2.jpg);
    /* background-position: unset; */
    background-repeat: no-repeat;
    background-position: 10px 300px;

    z-index: 1;
}

