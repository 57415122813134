/* @import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@700&display=swap'); */
*{
   
    box-sizing: border-box;
    margin: 0;
}


:root{
    --heights: 95vh;
    --widths: 100%;
}

.slider-container { 
    width: auto;
    height: auto;
    background: url(../../Media/Img/backgrounds/fondoGris1.jpg);
    /* height: var(--heights);
    width: var(--widths); */
    position: relative;
    /* display: flex; */
    justify-content: center;
    /* align-items: start; */
    margin: auto;
    overflow: hidden;


}
.carousel{
    /* border: 2px solid rgb(58, 58, 58);
    display: flex; */
    width: 100%;
}
.slides{
    display: flex;
    justify-content: center;
    width: 100%;
    /* border: 2px solid rgb(17, 88, 17); */
    /* align-items: center; */
    /* height:   var(--heights);
    width: var(--widths); */
    /* max-width: 800px;
    position: absolute;
    text-align: center; */
}

.boxSlides{
    width: 100% !important;
    max-height: 700px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: start;
    /* justify-content: space-around; */
    /* flex-wrap: wrap; */
    z-index: 100;
    /* border: 2px solid rgb(26, 28, 141); */
}
.boxImage{
    /* position: absolute;  */
    max-width: 900px;
    height: 500px;
    /* border: 3px solid rgb(0, 0, 0); */
    display: flex;
    align-items: center;
    /* z-index: 100; */
}

.slide-image{
    width: 90%;
    height: 500px;
    /* max-width: 1200px;
    height: 500px; */
   margin: auto;      
    /* object-fit: cover; */
   
}
.active{
    display: inline-block;
}

.inactive{
    display: none;
}



.slide-title{
    letter-spacing: 5px;
    font-size: 32px;
}
.slide-title, .slide-text{
    
    width: 100%;
    height: 100%;
    color: black;
    
    /* position: absolute; */
    text-align: center;
    top: 40%;
    z-index: 10;
}

.slide-text {
    font-family: 'Chakra Petch', sans-serif;
    font-weight: lighter;
    top: 65%;
    font-size: 1.2rem;
    text-align: justify;
}
.arrows{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.prev, .next {
   
    color: black;
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 60px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;

}

.prev:hover, .next:hover{
    color: #CB9C0D;
    background-color: rgba(0, 0, 0, 0.6 );
    transition: all 0.2s ease-in;
}

.next{
    right:  0;
    border-radius: 5px 0px 0px 5px;

}

.all-dots{
    /* padding-top: 50px; */
    width: 100%;
    height: 80px;
    /* position: absolute; */
    display: flex;
    /* top: 94%; */
    align-items: center;
    align-content: center;
    align-self: center;
    justify-content: center;
    z-index: 200;


}
.all-dots-modal{
    /* padding-top: 50px; */
    width: 100%;
    /* height: 80px; */
    position: absolute;
    display: flex;
    top: 90%;
    align-items: center;
    align-content: center;
    align-self: center;
    justify-content: center;
    z-index: 200;


}
.dot{
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0px 3px;
    background-color: rgba(197, 197, 197, 0.3);
    border-radius: 50%;
    display: inline-block;
}

.active-dot, .dot:hover {
    background-color: #CB9C0D;
}



.boxDescription{
    /* border: 1px solid rgb(141, 46, 46); */
    display: flex;
    flex-direction: column;
    max-width: 90%;
    text-align: center;
    
    justify-content: center;
    align-items: center;
    margin: auto;
}
.title-fabricamos{
    /* font-family: 'Bungee', cursive; */
    padding-top: 30px ;
    text-align: center;
    font-size: 1rem;
    z-index: -1;

    
}
.title-descubre{
    padding: 2rem;
    font-size: 50px;
    text-align: center;
}
@media screen and (max-width: 768px) {
    .slider-container, .slides{
    /* height: 90vh; */
    }
    .slide-image{
        height: auto;
    }
    .boxSlides{
        width: 100%;
    }
    .boxDescription{
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin: auto;
        
    }
    .slide-title{
        font-size: 32px;
    }
    .slide-text {
        margin-top: 1rem;
        font-size: 1rem;
        /* justify-content: center; */
        text-align: center;
    }
    .title-fabricamos{
        font-size: 2rem;
    }
}
